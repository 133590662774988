import chatbotApiCall from "./chatbotApiCall";

const roleApi = {
  getAll: async (_start: number = 0, _end: number = 100, _sort: string = "", _order: string = "") => {
    let url = `/roles`;
    const response: any = await chatbotApiCall(url, { params: { _start, _sort, _end, _order } });
    return response;
  },
  getOneById: async () => {},
  create: async () => {},
  update: async (id: number) => {},
  delete: async (id: number) => {},
};

export default roleApi;
