import React, { useCallback, useEffect, useState } from "react";

import { useHistory, useLocation } from "react-router-dom";

import authApi from "./api-service/authApi";
import PendingPage from "./component/PendingPage";
import jwt from "jsonwebtoken";
import Login from "./component/Login";

interface AppContainerPropsType {
  children?: React.ReactNode;
}

export interface UserInfo {
  id: number;
  accountMail: string;
  privileges: Array<string>;
  iat: number;
  exp: number;
}

declare global {
  interface Window {
    userInfo: UserInfo | any;
  }
}

const AppContainer = ({ children }: AppContainerPropsType) => {
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCheckToken, setIsCheckToken] = useState<boolean>(true);
  const [allowAccess, setAllowAccess] = useState<boolean>(false);

  const login = useCallback(async (openIdToken) => {
    try {
      setIsLoading(true);
      const result = await authApi.getAccessToken(openIdToken);
      setAllowAccess(result);
    } catch (e) {
      setAllowAccess(false);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      window.userInfo = jwt.decode(token);
      setAllowAccess(true);
    }
    setIsCheckToken(false);
  }, []);

  useEffect(() => {
    const pathname = history.location.pathname;
    if (pathname === "/chatbot/user" || pathname === "/") {
      const token = query.get("token") || null;
      if (token) {
        login(token);
      }
    }
  }, []);

  return isCheckToken ? null : isLoading ? <PendingPage /> : allowAccess ? <React.Fragment>{children}</React.Fragment> : <Login />;
};

export default AppContainer;
