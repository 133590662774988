import React from "react";
import {SettingFilled} from "@ant-design/icons";

const PendingPage: React.FC = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100vw",
                height: "100vh",
                fontSize: "100px",
            }}
        >
            <SettingFilled spin/>
        </div>
    );
};

export default PendingPage;
