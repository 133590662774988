import React, { FC } from "react";
import { Layout, Menu } from "antd";
import routers from "../../routers";
import { Link, Switch, Route } from "react-router-dom";
import User from "../../sence/user";
import iconChatbot from "../../chatbot.svg";

const { Header, Content, Sider } = Layout;

interface AppLayoutPropsType {
  children?: React.ReactNode;
}

const AppLayout: FC<AppLayoutPropsType> = () => {
  return (
    <Layout>
      <Sider
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
        }}
      >
        <div
          className="logo"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={iconChatbot} style={{ height: "64px" }} alt={"icon chatbot"} />
        </div>
        <Menu theme="dark" mode="inline" defaultSelectedKeys={["4"]}>
          {routers.map((router, index) => (
            <Menu.Item key={index} icon={router.icon()}>
              <Link to={router.linkTo}>{router.title}</Link>
            </Menu.Item>
          ))}
        </Menu>
      </Sider>
      <Layout className="site-layout" style={{ marginLeft: 200, overflowY: "scroll" }}>
        <Header className="site-layout-background" style={{ padding: 0 }} />
        <Content
          style={{
            overflow: "scroll",
            minHeight: "100vh",
            position: "fixed",
            padding: "15px",
            width: "calc(100% - 200px)",
            backgroundColor: "#f0f2f5",
          }}
        >
          <Switch>
            {routers.map((router, index) => (
              <Route
                key={index}
                path={router.linkTo}
                component={() => {
                  return router.component();
                }}
              />
            ))}
            <Route component={User} />
          </Switch>
        </Content>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
