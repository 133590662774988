import React, { FC } from "react";
import AppLayout from "./component/layout/Layout";
import { BrowserRouter as Router } from "react-router-dom";
import AppContainer from "./AppContainer";

const App: FC = () => (
  <Router>
    <AppContainer>
      <AppLayout />
    </AppContainer>
  </Router>
);

export default App;
