import React, { FC, useState, useEffect, useCallback } from "react";
import { Table } from "antd";

import { FilterType } from "../../common/commonModels";
import { RoleType } from "./models";
import "./Role.css";
import Search from "antd/lib/input/Search";
import roleApi from "../../api-service/roleApi";

const RoleList: FC = () => {
  const [roles, setRoles] = useState<RoleType[]>([]);
  const [totalRoles, setTotalRoles] = useState<number>(0);
  const [filter, setFilter] = useState<FilterType>({
    _start: 0,
    _end: 100,
    _sort: "",
    _order: "",
  });

  const [searchValue, setSearchValue] = useState<string>("");
  const [searchData, setSearchData] = useState<RoleType[]>([]);

  const loadRoles = useCallback(async () => {
    const { data = [], total = 0 } = await roleApi.getAll(filter._start, filter._end, filter._sort, filter._order);
    setRoles(data);
    setTotalRoles(total);
  }, [filter]);

  useEffect(() => {
    loadRoles();
  }, [loadRoles]);

  useEffect(() => {
    const mSearchData = roles.filter(
      (role) => role.command.toLowerCase().includes(searchValue.toLowerCase()) || role.description.toLowerCase().includes(searchValue.toLowerCase())
    );
    setSearchData(mSearchData);
  }, [roles, searchValue]);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Command Prefix",
      dataIndex: "command",
    },
    {
      title: "Description",
      dataIndex: "description",
    },
  ];
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "80vh",
        }}
      >
        <div style={{ marginBottom: "10px" }}>
          <Search placeholder="Nhập lệnh hoặc mô tả" style={{ maxWidth: 500 }} onChange={(event) => setSearchValue(event.target.value)} />
        </div>
        <Table columns={columns} dataSource={searchData} rowKey="id" />
      </div>
    </div>
  );
};

export default RoleList;
