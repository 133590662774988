import React from "react";

import { notification } from "antd";

export const openNotificationSuccess = (message?: string) => {
  notification.success({
    message: message || "Success",
    placement: "bottomRight",
  });
};

export const openNotificationError = (message?: string) => {
  notification.error({
    message: message || "Error",
    placement: "bottomRight",
  });
};
