import React, {useState} from "react";
import {Button, Input} from "antd";
import {Grid, Col, Row} from "antd";
import {UserOutlined, KeyOutlined} from '@ant-design/icons';
import authApi from '../api-service/authApi';

import iconChatbot from "../chatbot.svg";
import {openNotificationError, openNotificationSuccess} from "./CustomNotification";

const Login: React.FC = () => {
    const [email, setEmail] = useState('');
    const [otp, setOTP] = useState('');
    const handleSendOTP = async () => {
        // window.location.href = "https://ast.fpt.net/openid";
        const data = {
            email: email
        }
        const rs = await authApi.getOTP(data);
        openNotificationSuccess("Success!");
    };
    const handleLogin = async () => {
        const data = {
            email: email,
            otp: otp
        }
        const rs = await authApi.verifyOTP(data);
        console.log(rs);
        if(rs){
            window.location.href = "/";
        }
        else{
            openNotificationError("Err!");
        }
    };

    const handleOnChangeEmail = (value: string) => {
        setEmail(value);
    }

    const handleOnChangeOTP = (value: string) => {
        setOTP(value);
    }

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                width: "100vw",
                height: "100vh",
                fontSize: "36px",
            }}
        >
            <img src={iconChatbot} style={{height: "128px"}} alt={"icon chatbot"}/>
            <Row>
                <Col span={24}>
                    <Input size="large" placeholder="Email" prefix={<UserOutlined/>}
                           value={email} onChange={(e) => handleOnChangeEmail(e.target.value)}/>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Input size="large" placeholder="OTP" prefix={<KeyOutlined/>}
                           value={otp} onChange={(e) => handleOnChangeOTP(e.target.value)}/>
                </Col>
            </Row>
            <Row>
                <Col span={10}>
                    <Button onClick={handleSendOTP}>
                        Get OTP
                    </Button>
                </Col>
                <Col span={4}></Col>
                <Col span={10}>
                    <Button type="primary" onClick={handleLogin}>
                        Login
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

export default Login;
