import env from "../enviroment";
import axios from "axios";
import jwt from "jsonwebtoken";
import { openNotificationError } from "../component/CustomNotification";
import chatbotApiCall from "./chatbotApiCall";

const authApi = {
  loginByAstOpenid: () => {
    // window.location.replace("https://ast.fpt.net/openid/");
    // window.location.replace("/");
    localStorage.removeItem("accessToken");
  },
  getAccessToken: async (token: string) => {
    try {
      const url = env.baseUrl + "/auth/login-by-ast-openid-token";
      const response = await axios.post(url, {
        token: token,
      });
      const accessToken = await response.data?.accessToken;
      if (!accessToken) {
        throw new Error("Access token not found");
      }
      window.userInfo = jwt.decode(accessToken);
      window.localStorage.setItem("accessToken", accessToken);
      return true;
    } catch (e) {
      openNotificationError("LOGIN FAILED" + e.message);
      return false;
    }
  },
  callAPICheckAuthentication: async (token: string): Promise<any> => {
    try {
      const url = env.baseUrl + `/auth?token=${token}`;
      const res = await fetch(url);
      return res.json();
    } catch (error) {
      console.log(error);
    }
  },
  getOTP: async (data: any) => {
    const url = `/get-otp`;
    const response: any = await chatbotApiCall(url, { data: data, method: "POST" });
    return response;
  },

  verifyOTP: async (data : any) => {
    const url = `/verify-otp`;
    const response: any = await chatbotApiCall(url, { data: data, method: "POST" });
    const accessToken = await response.accessToken;
    if (!accessToken) {
      throw new Error("Access token not found");
    }
    window.userInfo = jwt.decode(accessToken);
    window.localStorage.setItem("accessToken", accessToken);
    return true;
  }
};

export default authApi;
