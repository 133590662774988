import chatbotApiCall from "./chatbotApiCall";
import { UserCreateType, UserEditType } from "../sence/user/models";

const userApi = {
  getAll: async (_start: number = 0, _end: number = 100, _sort: string = "", _order: string = "") => {
    let url = "/users";
    const response: any = await chatbotApiCall(url, {
      params: { _start, _end, _sort, _order },
    });
    return response;
  },
  getOneById: async (id: number) => {
    let url = `/users/${id}`;
    const response: any = await chatbotApiCall(url);
    return response;
  },
  create: async (user: UserCreateType) => {
    let url = `/users`;
    const response: any = await chatbotApiCall(url, { data: user, method: "POST" });
    return response;
  },
  update: async (user: UserEditType) => {
    let url = `/users/${user.id}`;
    const response: any = await chatbotApiCall(url, { data: user, method: "PUT" });
    return response;
  },
  deleteById: async (id: number) => {
    let url = `/users/${id}`;
    const response: any = await chatbotApiCall(url, { method: "DELETE" });
    return response;
  },
  getRequestHistoryOfUser: async (accountMail: string) => {
    let url = `/users/${accountMail}/statistic/request-history`;
    const response: any = await chatbotApiCall(url);
    return response;
  },
  getTopUsingChatbot: async () => {
    const url = `/users/statistic/top-using-chatbot`;
    const response: any = await chatbotApiCall(url);
    return response;
  },
};

export default userApi;
