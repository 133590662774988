import React, { FC, useState, useCallback, useEffect } from "react";
import GroupCreate from "./GroupCreate";
import { GroupCreateType, GroupType, GroupEditType } from "./models";
import { openNotificationSuccess, openNotificationError } from "../../component/CustomNotification";
import { FilterType } from "../../common/commonModels";
import { Space, Button, Table, Tag, Modal } from "antd";
import { EditOutlined, DeleteOutlined, PlusCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { RoleType } from "../role/models";
import GroupEdit from "./GroupEdit";
import groupApi from "../../api-service/groupApi";
import Search from "antd/lib/input/Search";

const DEFAULT_SELECTED_GROUP = {
  id: 0,
  name: "",
  description: "",
  roles: [],
};

const GroupList: FC = () => {
  // HANDLE OPEN CLOSE
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isCreate, setIsCreate] = useState<boolean>(true);
  const [groups, setGroups] = useState<GroupType[]>([]);
  const [totalGroups, setTotalGroups] = useState<number>(0);

  const [searchValue, setSearchValue] = useState<string>("");
  const [searchData, setSearchData] = useState<GroupType[]>([]);

  const [selectedGroup, setSelectedGroup] = useState<GroupType>(DEFAULT_SELECTED_GROUP);

  const onCloseEditAndCreate = () => {
    setIsCreate(false);
    setIsEdit(false);
  };

  const openEdit = () => {
    onCloseEditAndCreate();
    setIsEdit(true);
  };

  const openCreate = () => {
    onCloseEditAndCreate();
    setIsCreate(true);
  };

  const [filter, setFilter] = useState<FilterType>({
    _start: 0,
    _end: 10000,
    _sort: "",
    _order: "",
  });
  // HANDLE LOAD
  const loadGroups = useCallback(async () => {
    const { data = [], total } = await groupApi.getAll(filter._start, filter._end, filter._sort, filter._order);
    setGroups(data);
    setTotalGroups(total);
  }, [filter]);

  useEffect(() => {
    loadGroups();
  }, [loadGroups]);

  const handleOnPost = useCallback(
    async (group: GroupCreateType) => {
      const { data = {} } = await groupApi.create(group);
      const message = `Created ${data.name}`;
      openNotificationSuccess(message);
      await loadGroups();
    },
    [loadGroups]
  );

  const handleOnDelete = useCallback(
    async (id: number) => {
      const { data } = await groupApi.delete(id);
      openNotificationSuccess(`Delete ${data?.name} success`);
      await loadGroups();
    },
    [loadGroups]
  );
  const handleOnSave = useCallback(
    async (group: GroupEditType) => {
      const { data } = await groupApi.update(group);
      openNotificationSuccess(`Update ${data?.name} success`);
      await loadGroups();
    },
    [loadGroups]
  );
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
    },
    {
      title: "Roles",
      dataIndex: "roles",
      render: (roles: RoleType[]) => {
        if (roles) {
          return (
            <div>
              {roles.map((role, index) => (
                <Tag key={index} color="processing" style={{ marginTop: "5px" }}>
                  {role.command}
                </Tag>
              ))}
            </div>
          );
        }
        return null;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (group: GroupType) => {
        return (
          <Space className="action">
            <Button
              type="ghost"
              className="btn"
              icon={<EditOutlined />}
              onClick={() => {
                setSelectedGroup(group);
                openEdit();
              }}
            />
            <Button
              danger
              className="btn"
              icon={<DeleteOutlined />}
              onClick={() => {
                Modal.confirm({
                  title: "Xác nhận xóa",
                  icon: <ExclamationCircleOutlined color={"red"} />,
                  content: `Xóa group: ${group.name}`,
                  okText: "Xác nhận",
                  cancelText: "Hủy",
                  onOk: () => {
                    handleOnDelete(group.id);
                  },
                });
              }}
            />
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    const effect = () => {
      const mSearchData = groups.filter((group) => group.name.toLowerCase().includes(searchValue.toLowerCase()) || group.name.toLowerCase().includes(searchValue.toLowerCase()));
      setSearchData(mSearchData);
    };

    const time = setTimeout(effect, 300);
    return () => clearTimeout(time);
  }, [groups, searchValue]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "80vh",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <Search placeholder="Tên nhóm hoặc mô tả" style={{ maxWidth: 500 }} onChange={(event) => setSearchValue(event.target.value)} />
          <Button type="primary" onClick={openCreate} icon={<PlusCircleOutlined />}>
            Create
          </Button>
        </div>
        <Table columns={columns} dataSource={searchData} rowKey="id" />
      </div>

      {isCreate ? (
        <GroupCreate
          close={() => {
            onCloseEditAndCreate();
          }}
          onSave={(group: GroupCreateType) => {
            handleOnPost(group);
          }}
        />
      ) : null}
      {isEdit ? (
        <GroupEdit
          onClose={() => {
            onCloseEditAndCreate();
          }}
          onSave={(group: GroupEditType) => {
            handleOnSave(group);
          }}
          groupId={selectedGroup.id}
        />
      ) : null}
    </div>
  );
};

export default GroupList;
