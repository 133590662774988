import chatbotApiCall from "./chatbotApiCall";

const logApi = {
  getAll: async (accountMail = "", timestampOrder = "asc") => {
    let url = `/log-chat`;
    const response: any = await chatbotApiCall(url, { params: { accountMail, timestampOrder } });
    return response;
  },
};

export default logApi;
