import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "antd";
import userApi from "../../api-service/userApi";

interface PropsType {
  visible: boolean;
  onCancle: any;
}

interface TopUsersModel {
  accountMail: string;
  totalAccess: number;
}

const TopUserRequest: React.FC<PropsType> = ({ visible = false, onCancle }: PropsType) => {
  const [users, setUsers] = useState<Array<TopUsersModel>>([]);
  const loadTopUsingChatbot = useCallback(async () => {
    const { data = [] } = await userApi.getTopUsingChatbot();
    const mUsers = [{ accountMail: "Account Mail", totalAccess: "Total Request" }, ...data];

    setUsers(mUsers);
  }, []);

  useEffect(() => {
    if (visible) {
      loadTopUsingChatbot();
    }
  }, [loadTopUsingChatbot, visible]);

  return (
    <Modal visible={visible} onCancel={onCancle} footer={[]}>
      <h3 style={{ margin: "10px 0" }}>Top user request</h3>
      <div className="TopUserList">
        {users.map((user, index) => {
          return (
            <div key={user.accountMail} className="TopUserListItem">
              <div>{user.accountMail}</div>
              <div>{user.totalAccess}</div>
            </div>
          );
        })}
      </div>
    </Modal>
  );
};

export default TopUserRequest;
