import React, { FunctionComponent } from "react";
import { Alert } from "antd";

export interface AccessDeniedProps {
  title?: string;
  content?: string;
}

const AccessDenied: FunctionComponent<AccessDeniedProps> = ({
  title = "Từ chối truy cập",
  content = "Quyền truy cập bị từ chối.\n Vui lòng liên hệ tuongdm2 để được cấp quyền truy cập",
}) => {
  return <Alert message={title} type="warning" description={content} />;
};

export default AccessDenied;
