import React, { useState, useEffect, useCallback } from "react";
import { Card, Button, Input, Select, Avatar } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";

import { GroupType } from "../group/models";
import { UserCreateType } from "./models";

import "./User.css";
import groupApi from "../../api-service/groupApi";

interface UserPropsType {
  close: Function;
  onSave: Function;
}

const UserCreate = ({ close, onSave }: UserPropsType) => {
  const [record, setRecord] = useState<UserCreateType>({
    accountMail: "",
    groups: [],
  });

  const [groupOptions, setGroupOptions] = useState<GroupType[]>([]);

  const loadGroupOptions = useCallback(async () => {
    const { data = [] } = await groupApi.getAll(0, 10000);
    setGroupOptions(data);
  }, []);

  useEffect(() => {
    loadGroupOptions();
  }, [loadGroupOptions]);

  return (
    <div className="UserCreate">
      <Card
        title="Create new user"
        bordered={false}
        extra={<Button onClick={() => close()} icon={<CloseCircleOutlined />} />}
        actions={[
          <Button type="primary" onClick={() => onSave(record)}>
            Lưu
          </Button>,
        ]}
      >
        <div className="txtLabel">Account mail</div>
        <Input
          value={record.accountMail}
          onChange={(event) => {
            setRecord({ ...record, accountMail: event.target.value });
          }}
        />
        <div className="txtLabel">Groups</div>
        <Select
          mode="multiple"
          style={{ width: "100%" }}
          placeholder="Select groups"
          onChange={(selectedIds: number[]) => setRecord({ ...record, groups: selectedIds })}
          filterOption={(inputValue, option) => {
            const result = option && option.label && option.label.toString().toLocaleLowerCase().includes(inputValue.toString().toLocaleLowerCase());
            return result ? result : false;
          }}
        >
          {groupOptions.map((group, index) => (
            <Select.Option key={index} value={group.id} label={group.name}>
              <div>
                <Avatar
                  size="small"
                  style={{
                    background: "#10899e",
                    width: "10px",
                    height: "10px",
                  }}
                />{" "}
                {group.name}
              </div>
            </Select.Option>
          ))}
        </Select>
      </Card>
    </div>
  );
};

export default UserCreate;
