import React, { useEffect, useState } from "react";
import authApi from "../api-service/authApi";
import { SettingFilled } from "@ant-design/icons";

interface PrivateComponentProps {
  children?: React.ReactNode;
  privilege: string;
  accessDenied?: React.ReactNode;
}

const PrivateComponent: React.FunctionComponent<PrivateComponentProps> = ({
  children,
  accessDenied,
  privilege,
}) => {
  const [allowAccess, setAllowAccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const userInfo = window.userInfo;
    try {
      if (!userInfo) {
        authApi.loginByAstOpenid();
      } else {
        const tokenExpiredAt = new Date(userInfo.tokenExpiredAt).getTime();
        const current = new Date().getTime();

        // < 10'
        if (tokenExpiredAt - current < 10 * 60 * 1000) {
          authApi.loginByAstOpenid();
        }

        const userPrivileges = userInfo.privileges;

        if (userPrivileges.indexOf(privilege) > -1) {
          setAllowAccess(true);
        }
      }
    } catch (e) {
      console.trace(e);
    } finally {
      setIsLoading(false);
    }
  }, [privilege]);

  return (
    <div>
      {isLoading ? (
        <SettingFilled spin />
      ) : allowAccess ? (
        <>{children}</>
      ) : accessDenied ? (
        <>{accessDenied}</>
      ) : (
        ""
      )}
    </div>
  );
};

export default PrivateComponent;
