import React from "react";
import UserList from "./UserList";
import PrivateComponent from "../../component/PrivateComponent";
import PRIVILEGE_CONSTANTS from "../../constant/privilegeContants";
import AccessDenied from "../../component/AccessDenied";

const User: React.FunctionComponent = () => {
  return (
    <PrivateComponent
      privilege={PRIVILEGE_CONSTANTS["@ADMIN"]}
      accessDenied={<AccessDenied />}
    >
      <UserList />
    </PrivateComponent>
  );
};

export default User;
