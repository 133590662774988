import chatbotApiCall from "./chatbotApiCall";
import { GroupCreateType, GroupEditType } from "../sence/group/models";

const groupApi = {
  getAll: async (_start: number = 0, _end: number = 100, _sort: string = "", _order: string = "") => {
    const url = "/groups";
    const response: any = await chatbotApiCall(url, {
      params: {
        _start,
        _end,
        _sort,
        _order,
      },
    });
    return response;
  },
  getOneById: async (id: number) => {
    const url = `/groups/${id}`;
    const response: any = await chatbotApiCall(url);
    return response;
  },
  create: async (group: GroupCreateType) => {
    const url = "/groups";
    const response: any = await chatbotApiCall(url, {
      data: group,
      method: "POST",
    });
    return response;
  },
  update: async (group: GroupEditType) => {
    const url = `/groups/${group.id}`;
    const response: any = await chatbotApiCall(url, {
      data: group,
      method: "PUT",
    });
    return response;
  },
  delete: async (id: number) => {
    const url = `/groups/${id}`;
    const response: any = await chatbotApiCall(url, {
      method: "DELETE",
    });
    return response;
  },
};

export default groupApi;
