import {
  UserOutlined,
  GroupOutlined,
  MacCommandOutlined,
  FieldTimeOutlined,
} from "@ant-design/icons";
import React from "react";
import User from "./sence/user";
import Role from "./sence/role";
import Group from "./sence/group";
import Logging from "./sence/logging";

interface RouterType {
  linkTo: string;
  icon: Function;
  title: string;
  component: Function;
}

const routers: RouterType[] = [
  // {
  //   linkTo: "/chatbot/home",
  //   component: () => <Home />,
  //   icon: () => <HomeOutlined />,
  //   title: "Home",
  // },
  {
    linkTo: "/chatbot/user",
    component: () => <User />,
    icon: () => <UserOutlined />,
    title: "User",
  },
  {
    linkTo: "/chatbot/group",
    component: () => <Group />,
    icon: () => <GroupOutlined />,
    title: "Group",
  },
  {
    linkTo: "/chatbot/role",
    component: () => <Role />,
    icon: () => <MacCommandOutlined />,
    title: "Role",
  },
  {
    linkTo: "/chatbot/log",
    component: () => <Logging />,
    icon: () => <FieldTimeOutlined />,
    title: "Log",
  },
];

export default routers;
