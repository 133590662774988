import React, { useState, useEffect, useCallback, useRef } from "react";
import LoggingList from "./LoggingList";
import LoggingFilter from "./LoggingFilter";
import { LoggingFilterType, LogsType } from "./model";
import logApi from "../../api-service/logApi";

const Logging = () => {
  const [logs, setLogs] = useState<any>([]);

  const [filter, setFilter] = useState<LoggingFilterType>({
    accountMail: "",
    timestampOrder: "desc",
  });

  const loadLogs = useCallback(async () => {
    const { data = [] } = await logApi.getAll(filter.accountMail, filter.timestampOrder);
    const mLogs = data.map((row: LogsType) => ({ ...row, timestamp: new Date(row.timestamp).toLocaleString("vi-VN") }));
    setLogs(mLogs);
  }, [filter]);

  useEffect(() => {
    const time = setTimeout(loadLogs, 300);
    return () => clearTimeout(time);
  }, [loadLogs]);

  const handleOnRefresh = useCallback(() => {
    setFilter({
      accountMail: "",
      timestampOrder: "desc",
    });
  }, []);

  return (
    <div style={{ width: "100%", height: "80vh", padding: "0 0 20px 0" }}>
      <LoggingFilter
        filter={filter}
        setFilter={setFilter}
        onRefresh={() => {
          handleOnRefresh();
        }}
      />
      <LoggingList logs={logs} />
    </div>
  );
};

export default Logging;
