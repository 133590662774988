import React, { FC, useState, useCallback, useEffect } from "react";

import { openNotificationSuccess } from "../../component/CustomNotification";
import { FilterType } from "../../common/commonModels";
import { Space, Button, Table, Tag, Modal } from "antd";
import { EditOutlined, DeleteOutlined, PlusCircleOutlined, HistoryOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { UserType, UserCreateType, UserEditType } from "./models";
import { GroupType } from "../group/models";
import UserCreate from "./UserCreate";
import UserEdit from "./UserEdit";
import Search from "antd/lib/input/Search";
import UserHistory from "./UserHistory";
import TopUserRequest from "./TopUserRequest";
import userApi from "../../api-service/userApi";

const UserList: FC = () => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isCreate, setIsCreate] = useState<boolean>(true);
  const [isOpenTopUserRequest, setIsOpenTopUserRequest] = useState<boolean>(false);

  const onCloseEditAndCreate = () => {
    setIsCreate(false);
    setIsEdit(false);
  };
  const openEdit = () => {
    onCloseEditAndCreate();
    setIsEdit(true);
  };
  const openCreate = () => {
    onCloseEditAndCreate();
    setIsCreate(true);
  };
  const [users, setUsers] = useState<UserType[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchedUser, setSearchedUser] = useState<UserType[]>([]);
  const [accountMailSelected, setAccountMailSelected] = useState<string>("");
  const [totalUsers, setTotalUsers] = useState<number>(0);
  const [seletedUser, setSelectedUser] = useState<UserType>({
    id: 0,
    accountMail: "",
    groups: [],
  });
  const [filter, setFilter] = useState<FilterType>({
    _start: 0,
    _end: 10000,
    _sort: "",
    _order: "",
  });
  const [isOpenUserHistory, setIsOpenUserHistory] = useState(false);

  const loadUsers = useCallback(async () => {
    const { data = [], total = 0 } = await userApi.getAll(filter._start, filter._end, filter._sort, filter._order);
    setUsers(data);
    setTotalUsers(total);
  }, [filter]);

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  useEffect(() => {
    const effect = () => {
      const mSearchedUser = users?.filter((value) => value.accountMail.includes(searchValue)) || [];
      setSearchedUser(mSearchedUser);
    };
    const time = setTimeout(effect, 300);
    return () => clearTimeout(time);
  }, [users, searchValue]);

  const handleOnPost = useCallback(
    async (user: UserCreateType) => {
      const { data }: { data: UserType } = await userApi.create(user);
      const message = `Created ${data.accountMail}`;
      openNotificationSuccess(message);
      await loadUsers();
    },
    [loadUsers]
  );

  const handleOnDelete = useCallback(
    async (id: number) => {
      const { data }: any = await userApi.deleteById(id);
      const message = `Deleted ${data?.accountMail}`;
      openNotificationSuccess(message);
      setSelectedUser({
        id: 0,
        accountMail: "",
        groups: [],
      });
      await loadUsers();
    },
    [loadUsers]
  );

  const handleOnSave = useCallback(
    async (user: UserEditType) => {
      const { data }: any = await userApi.update(user);
      const message = `Update user ${data?.accountMail} success`;
      openNotificationSuccess(message);
      await loadUsers();
    },
    [loadUsers]
  );

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "AccountMail",
      dataIndex: "accountMail",
    },
    {
      title: "Group",
      dataIndex: "groups",
      render: (groups: GroupType[]) => {
        if (groups) {
          return (
            <div>
              {groups.map((group, index) => (
                <Tag key={index} color="processing" style={{ marginTop: "5px" }}>
                  {group.name}
                </Tag>
              ))}
            </div>
          );
        }
        return null;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (user: UserType) => {
        return (
          <Space className="action">
            <Button
              shape="circle-outline"
              type="primary"
              onClick={() => {
                setAccountMailSelected(user.accountMail);
                setIsOpenUserHistory(true);
              }}
              icon={<HistoryOutlined />}
            />

            <Button
              type="ghost"
              className="btn"
              icon={<EditOutlined />}
              onClick={() => {
                setSelectedUser(user);
                openEdit();
              }}
            />
            <Button
              danger
              className="btn"
              icon={<DeleteOutlined />}
              onClick={() => {
                Modal.confirm({
                  title: "Xác nhận xóa",
                  icon: <ExclamationCircleOutlined />,
                  content: `Xóa user có account ${user.accountMail}`,
                  okText: "Xác nhận",
                  cancelText: "Hủy",
                  onOk: () => {
                    handleOnDelete(user.id);
                  },
                });
              }}
            />
          </Space>
        );
      },
    },
  ];
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "80vh",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <Search placeholder="Nhập tài khoản cần tìm" style={{ maxWidth: 500 }} onChange={(event) => setSearchValue(event.target.value)} />
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Button
              type="ghost"
              onClick={() => {
                setIsOpenTopUserRequest(true);
              }}
            >
              Top User Request
            </Button>
            <div style={{ width: "10px", height: "10px" }} />
            <Button type="primary" onClick={openCreate} icon={<PlusCircleOutlined />}>
              Create
            </Button>
          </div>
        </div>
        <Table columns={columns} dataSource={searchedUser} rowKey="id" />
      </div>

      {isCreate ? (
        <UserCreate
          close={() => {
            onCloseEditAndCreate();
          }}
          onSave={(user: UserCreateType) => {
            handleOnPost(user);
          }}
        />
      ) : null}
      {isEdit ? (
        <UserEdit
          onClose={() => {
            onCloseEditAndCreate();
          }}
          onSave={(user: UserEditType) => {
            handleOnSave(user);
          }}
          userId={seletedUser.id}
        />
      ) : null}
      <TopUserRequest visible={isOpenTopUserRequest} onCancle={() => setIsOpenTopUserRequest(false)} />
      <UserHistory visible={isOpenUserHistory} onCancel={() => setIsOpenUserHistory(false)} accountMail={accountMailSelected} />
    </div>
  );
};

export default UserList;
