import React, { useCallback, useEffect, useState } from "react";
import { Modal, Table } from "antd";
import "./User.css";
import userApi from "../../api-service/userApi";

interface PropsType {
  accountMail: string;
  visible: boolean;
  onCancel: any;
}

interface RequestHistory {
  accountMail: string;
  input: any;
  output: any;
  timestamp: any;
}

const UserHistory: React.FC<PropsType> = ({ visible = false, accountMail, onCancel }: PropsType) => {
  const [requestHistories, setRequestHistories] = useState<RequestHistory[]>([]);
  const loadUserHistory = useCallback(async () => {
    const { data = [] } = await userApi.getRequestHistoryOfUser(accountMail);
    const transformData = data.map((row: any) => ({
      timestamp: new Date(row.timestamp).toLocaleString("vi-VN"),
      input: row.input,
      output: row.output,
    }));
    setRequestHistories(transformData);
  }, [accountMail]);

  useEffect(() => {
    if (visible) {
      loadUserHistory();
    }
  }, [visible, loadUserHistory]);

  const columns = [
    {
      title: "Timestamp",
      dataIndex: "timestamp",
      key: "timestamp",
    },
    {
      title: "Input",
      dataIndex: "input",
      key: "input",
    },
    {
      title: "Ouput",
      dataIndex: "output",
      key: "output",
    },
  ];
  return (
    <Modal
      visible={visible}
      onCancel={() => {
        setRequestHistories([]);
        onCancel();
      }}
      footer={[]}
      width={850}
    >
      <h3>
        Request history of <span style={{ color: "red" }}>{accountMail}</span>{" "}
      </h3>
      <Table dataSource={requestHistories} columns={columns} />
    </Modal>
  );
};

export default UserHistory;
