import React, { useState, useEffect, useCallback } from "react";
import { Card, Button, Input, Select, Avatar } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";

import { RoleType } from "../role/models";
import { GroupCreateType } from "./models";

import "./Group.css";
import roleApi from "../../api-service/roleApi";

interface GrouppropsType {
  close: Function;
  onSave: Function;
}

const GroupCreate = ({ close, onSave }: GrouppropsType) => {
  const [record, setRecord] = useState<GroupCreateType>({
    name: "",
    description: "",
    roles: [],
  });
  const [roleOptions, setRoleOptions] = useState<RoleType[]>([]);

  const loadRoleOptions = useCallback(async () => {
    const { data = [] } = await roleApi.getAll(0, 10000);
    setRoleOptions(data);
  }, []);

  useEffect(() => {
    loadRoleOptions();
  }, [loadRoleOptions]);

  return (
    <div className="GroupCreate">
      <Card
        title="Create new group"
        bordered={false}
        extra={<Button onClick={() => close()} icon={<CloseCircleOutlined />} />}
        actions={[
          <Button type="primary" onClick={() => onSave(record)}>
            Lưu
          </Button>,
        ]}
      >
        <div className="txtLabel">Name</div>
        <Input
          value={record.name}
          onChange={(event) => {
            setRecord({ ...record, name: event.target.value });
          }}
        />
        <div className="txtLabel">Description</div>
        <Input
          value={record.description}
          onChange={(event) => {
            setRecord({ ...record, description: event.target.value });
          }}
        />
        <div className="txtLabel">Roles</div>
        <Select
          mode="multiple"
          style={{ width: "100%" }}
          placeholder="Select roles"
          onChange={(selectedIds: number[]) => setRecord({ ...record, roles: selectedIds })}
          filterOption={(inputValue, option) => {
            const result = option && option.label && option.label.toString().toLocaleLowerCase().includes(inputValue.toString().toLocaleLowerCase());
            return result ? result : false;
          }}
        >
          {roleOptions.map((role, index) => (
            <Select.Option key={index} value={role.id} label={role.command}>
              <div>
                <Avatar
                  size="small"
                  style={{
                    background: "#10899e",
                    width: "10px",
                    height: "10px",
                  }}
                >
                  {role.command[0]}
                </Avatar>{" "}
                {role.command}
              </div>
            </Select.Option>
          ))}
        </Select>
      </Card>
    </div>
  );
};

export default GroupCreate;
