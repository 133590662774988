import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import enviroment from "../enviroment";
import { openNotificationError } from "../component/CustomNotification";
import { notification } from "antd";
import authApi from "./authApi";

const chatbotApiCall = axios.create();

chatbotApiCall.defaults.baseURL = enviroment.baseUrl;

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  config.headers["access-token"] = localStorage.getItem("accessToken");
  return config;
};

const onRequestError = (error: AxiosError): void => {
  openNotificationError(error.message);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response.data;
};

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  const toastMessage = error?.response?.data?.message || "Đã có lỗi xảy ra";
  const description = error?.response?.data?.error || "";
  const status = error?.response?.status;

  if (status === 500) {
    notification.error({ message: toastMessage, description: description });
  } else if (status === 401 || status === 403) {
    authApi.loginByAstOpenid();
  } else {
    notification.error({ message: toastMessage, description: description });
  }
  return Promise.reject(error);
};

// before request
chatbotApiCall.interceptors.request.use(onRequest, onRequestError);
chatbotApiCall.interceptors.response.use(onResponse, onResponseError);

export default chatbotApiCall;
