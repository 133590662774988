import React from "react";
import { LoggingFilterType } from "./model";
import { Input, Button } from "antd";
import "./styles.css";
import { ReloadOutlined, CaretUpOutlined, CaretDownOutlined, CloseOutlined } from "@ant-design/icons";

interface PropsType {
  filter: LoggingFilterType;
  setFilter: Function;
  onRefresh: Function;
}
const LoggingFilter = ({ filter, setFilter, onRefresh }: PropsType) => {
  const { accountMail, timestampOrder } = filter;
  const handleOnClickTimestampOrderButton = () => {
    setFilter({
      accountMail,
      timestampOrder: timestampOrder === "asc" ? "desc" : "asc",
    });
  };
  const handleOnChangeAccountMailInput = (newValue: string) => {
    setFilter({
      accountMail: newValue,
      timestampOrder,
    });
  };
  return (
    <div className="LoggingFilter">
      <div className="LoggingFilterLeft">
        <Input
          placeholder="Account mail"
          value={accountMail}
          onChange={(e) => handleOnChangeAccountMailInput(e.target.value)}
          suffix={<CloseOutlined onClick={() => handleOnChangeAccountMailInput("")} />}
        />
        <div className="space" />
        <Button icon={timestampOrder === "asc" ? <CaretUpOutlined /> : <CaretDownOutlined />} onClick={handleOnClickTimestampOrderButton}>
          Timestamp Order
        </Button>
      </div>
      <div className="LoggingFilterRight">
        <Button
          shape="circle"
          icon={<ReloadOutlined />}
          type="primary"
          onClick={() => {
            onRefresh();
          }}
        />
      </div>
    </div>
  );
};

export default LoggingFilter;
