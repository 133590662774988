import React from "react";
import { LogsType } from "./model";
import { Table } from "antd";

interface PropsType {
  logs: Array<LogsType>;
}

const columns = [
  {
    title: "Account Mail",
    dataIndex: "accountMail",
    key: "accountMail",
  },
  {
    title: "Input",
    dataIndex: "input",
    key: "input",
  },
  {
    title: "Output",
    dataIndex: "output",
    key: "output",
  },
  {
    title: "Timestamp",
    dataIndex: "timestamp",
    key: "timestamp",
  },
];

const LoggingList = ({ logs = [] }: PropsType) => {
  return (
    <div style={{ display: "flex" }}>
      <Table dataSource={logs} columns={columns} rowKey="timestamp" style={{ width: "80vw", marginBottom: "20px" }} />
    </div>
  );
};

export default LoggingList;
